
import React, { useState, useMemo } from "react";
import {
  ProvisionalBody,
  ProvisionalContentMedium,
} from "../types";
import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage, AdvancedVideo } from "@cloudinary/react";
import { thumbnail, fill } from "@cloudinary/url-gen/actions/resize";

type Props = {
  header: JSX.Element;
  body: ProvisionalBody;
  errors: {message: string, action?: () => void }[];
  hContentMedia: Record<number, ProvisionalContentMedium>;
  variables: Record<string, string | null>;

};

const cld = new Cloudinary({
  cloud: {
    cloudName: "dcmekntwa",
  },
});

const PostPreview = ({
  errors,
  body,
  hContentMedia,
  variables,
  header,
}: Props) => {
  const selectedCM = body.body_media_attributes.map(bma => hContentMedia[bma.content_medium_provisional_id])

  const focus = useMemo(() => {
    const mediaCount = selectedCM.length
    const videoCount = selectedCM.filter(cm => cm.serialized_file.resource_type === "video").length

    if (body.link_url) {
      return "link"
    }
    if (mediaCount > 0) {
      if (mediaCount > 1) {
        return "images"
      } else if (videoCount > 0) {
        return "video"
      } else {
        return "image"
      }
    } else {
      return "none"
    }
  }, [selectedCM, body.link_url])

  const parsedMessage = useMemo(() => (
    Object.entries(variables).reduce(
      (accMessage, [key, value]) => accMessage.replaceAll(key, value || ""),
      body.message || "")
  ), [body.message])

  const parsedLink = useMemo(() => (
    Object.entries(variables).reduce(
      (accMessage, [key, value]) => accMessage.replaceAll(key, value || ""),
      body.link_url || "")
  ), [body.link_url])

  return (
    <div
      className={`flex shadow flex-col border rounded w-full max-w-lg snap-center h-fit ${errors.length > 0 ? "border-red-500" : ""
        }`}
    >
      {header}
      <p className="p-4 text-sm text-gray-700 overflow-y-auto max-h-80 whitespace-pre-wrap">{parsedMessage}</p>
      <div className="flex justify-start">
        {["images", "image"].includes(focus) && (
          <div className={`p-2 relative flex flex-col flex-wrap ${focus === "images" ? "w-full" : "w-fit"} h-64`}>
            {selectedCM.map((cm, i) => {
              let style = ""
              if (focus === "images") {
                style = "object-cover border border-slate-200 "
                switch (i) {
                  case 0:
                    style += "w-1/2 h-full rounded-l-md m-auto";
                    break;
                  case 1:
                    style += selectedCM.length <= 2 ? "w-1/2 h-full rounded-r-md" : "w-1/2 h-1/2 rounded-tr-md"
                    break;
                  case 2:
                    style += "relative w-1/2 h-1/2 object-cover overflow-hidden border rounded-br-md"
                    break;
                  default:
                    style = "hidden"
                    break;
                }
              }

              if (focus === "image") {
                style = "object-contain border border-slate-200 h-full w-full rounded-md"
              }
              return <>
                <AdvancedImage
                  className={style}
                  key={`cm-${i}`}
                  cldImg={cld
                    .image(cm.serialized_file.public_id)
                    .setAssetType(cm.serialized_file.resource_type)
                    .resize(thumbnail().width(320))}
                />
                {(i === 2 && selectedCM.length >= 3) && (
                  <div
                    className="absolute bottom-0 right-0 w-1/2 bg-gradient-to-t from-white h-20 flex"
                  ><span className="absolute bottom-2 right-2 pr-2">+{selectedCM.length - 2}</span>
                  </div>
                )}
              </>
            })}
          </div>
        )}
        {focus === "video" && (
          <div className={`p-2 relative flex flex-col flex-wrap w-full h-64`}>
            <AdvancedVideo
              className="h-full w-full bg-gray-900 rounded-md"
              cldVid={
                cld.video(selectedCM[0].serialized_file.public_id)
                  .setAssetType(selectedCM[0].serialized_file.resource_type)
                  .resize(fill().width(320))
              } controls />
          </div>
        )}
        {focus === "link" && (
          <a className=" block p-2 w-full" href={"https://" + parsedLink?.replace("https://", "") || undefined} target="_blank" >
            <div className=" w-full flex flex-row items-center rounded-md bg-white overflow-hidden space-x-2 border border-slate-300 hover:bg-slate-100">
              {selectedCM.length > 0 && (
                <AdvancedImage
                  className="h-24 w-24 object-cover"
                  cldImg={cld
                    .image(selectedCM[0].serialized_file.public_id)
                    .setAssetType(selectedCM[0].serialized_file.resource_type)
                    .resize(thumbnail().width(320))}
                />
              )}
              <div className="h-full w-full">
                <div className="space-y-0.5 h-16 p-1">
                  <p className="font-medium text-gray-700">
                    {parsedLink && URL.canParse(parsedLink) ? new URL(parsedLink).hostname : ""}
                  </p>
                  <p className="text-sm text-gray-500 line-clamp-2">{body.link_description}</p>
                </div>
              </div>
            </div>
          </a>

        )}
      </div>
    </div >
  );
};

export default PostPreview;
