import React, { useState, useMemo, useCallback, memo, useEffect } from "react";
import { ChannelAccount, ProvisionalBody, ProvisionalContentMedium, ProvisionalPost } from "../types";
import { ChannelAccountsSelector, ChannelAccountsUiElement } from "./ChannelAccountBodyComponents";
import PublicationManager from "./PublicationManager";
import { HiddenInput } from "./DynamicFormGenerator";
import BodyEditor from "./BodyEditor";
import { PaperAirplaneIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import Tooltip from "./tooltip";


type Props = {
  bodies: ProvisionalBody[];
  content_media: ProvisionalContentMedium[];
  post: ProvisionalPost;
  channel_accounts: (ChannelAccount & { id: number })[];
  variables: Record<string, string | null>;
}

const PostStateManager = (props: Props) => {
  console.log(props)

  // ### Tabs
  const mobileTabs = [
    { label: "Contenu", value: "content" },
    { label: "Publication", value: "publication" }
  ];
  const [activeMobileTab, setActiveMobileTab] = useState("content");

  const hChannelAccounts = useMemo(() => Object.fromEntries(props.channel_accounts.map(ca => [ca.id as number, ca])), [props.channel_accounts])

  const [hBodies, setHBodies] = useState<Record<number, ProvisionalBody>>(
    Object.fromEntries(props.bodies.map(b => [b.provisional_id, b]))
  )

  const [showModalForBodyProvId, setShowModalForBodyProvId] = useState<null | number>(props.post.id ? null : parseInt(Object.keys(hBodies)[0]));

  // Post
  const [post, setPost] = useState<ProvisionalPost>(props.post);

  // On garde en mémoire les pocs originaux pour l'édition
  const originalPocsByCa = useMemo(() => Object.fromEntries(
    props.post.post_on_channels_attributes
      .filter(p => p.id)
      .map(p => [p.channel_account_id, p.id])
  ), []) as Record<number, number>

  // Content Media
  const [hContentMedia, setHContentMedia] = useState<Record<number, ProvisionalContentMedium>>(
    Object.fromEntries(props.content_media.map(cm => [cm.provisional_id, cm]))
  )

  const switchBodyPocs = (targetIds: number[], bodyId: ProvisionalBody['provisional_id']) => {
    const finalPocs: ProvisionalPost["post_on_channels_attributes"][0][] = []
    const remainsTargetIds = new Set(targetIds)
    post.post_on_channels_attributes.forEach((poc) => {
      // Si le channel_account_id est dans les targetIds,
      // On associe ce POC à ce bodyId
      if (remainsTargetIds.has(poc.channel_account_id)) {
        finalPocs.push({ ...poc, body_provisional_id: bodyId })
      } else {
        // Si le channel_account_id n'est pas dans les targetIds :
        // Soit le POC n'avait pas ce bodyId => on le laisse tranquille et on le garde
        // Soit le POC avait ce bodyId => on supprime le POC
        if (poc.body_provisional_id !== bodyId) finalPocs.push(poc)
      }
      remainsTargetIds.delete(poc.channel_account_id)
    })
    // Si des targetsIds restent, on les rajoute
    remainsTargetIds.forEach(ca_id => (
      finalPocs.push({
        body_provisional_id: bodyId,
        forced_scheduled_at: "",
        channel_account_id: ca_id,
        ...defaultValuesForChannel(ca_id)
      })
    ))
    setPost(prev => ({ ...prev, post_on_channels_attributes: finalPocs }));

    // On supprime les bodies sans pocs
    const pocsBPid = finalPocs?.map(poc => poc.body_provisional_id)
    setHBodies(hBodies => {
      const nextBodies = Object.fromEntries(
        Object.entries(hBodies).filter(([pid, _]) => pocsBPid.includes(parseInt(pid)))
      )
      return nextBodies
    })
  }

  // les bodies dans l'ordre pour itérer
  const sortedBodyKeys = useMemo(() => {
    return Object.keys(hBodies)
      .map(p => parseInt(p))
      .sort()
  }, [Object.keys(hBodies).length]) // le nombre de body a changé

  // Callback avant la suppression d'un body
  const onBodyRemoval = useMemo(() => (bodyPid: number) => {
    const post_on_channels_attributes = post.post_on_channels_attributes
      .filter(poc => poc.body_provisional_id !== bodyPid)
    // On ouvre l'onglet du dernier body
    const lastBodyId = Object.keys(hBodies).filter(bId => parseInt(bId) !== bodyPid).at(-1) as string
    setDesktopActiveBody(parseInt(lastBodyId))
    setPost(post => ({ ...post, post_on_channels_attributes }))
  }, [post])

  // Pour la suppression d'un body vide
  const destroyBody = useCallback((bodyPid: number) => {
    setHBodies(({ [bodyPid]: _, ...keep }) => {
      const lastBodyId = Object.keys(keep).filter(bId => parseInt(bId) !== bodyPid).at(-1) as string
      setDesktopActiveBody(parseInt(lastBodyId))
      return keep
    });
  }, [])

  // Callback après la duplication d'un body
  const onBodyDuplication = useCallback((bodyPid: number) => {
    setShowModalForBodyProvId(bodyPid)
    setDesktopActiveBody(bodyPid)
  }, [hBodies])

  // Which body is active on desktop
  const [desktopActiveBody, setDesktopActiveBody] = useState(sortedBodyKeys[0])
  const isActive = (bPid: number) => bPid === desktopActiveBody

  const defaultValuesForChannel = (channelAccountId: number) => {
    const channelAccount = hChannelAccounts[channelAccountId]
    return {
      ...(channelAccount?.channel?.identifier === "tiktok" && {
        channel_specific_data: {
          disable_comments: true,
          disable_duet: true,
          disable_stitch: true,
          privacy_level: null,
        }
      }),
    }
  }

  // Prevent recreating the selector component on renders
  const memoizedSelectorRenderer = useMemo(() => (
    bPid: ProvisionalBody["provisional_id"],
    buttonUI: JSX.Element
  ) => {
    return (
      <ChannelAccountsSelector
        bPid={bPid}
        post={post}
        destroyEmptyBody={destroyBody}
        hChannelAccounts={hChannelAccounts}
        switchBodyPocs={switchBodyPocs}
        showModalForBodyProvId={showModalForBodyProvId}
        setShowModalForBodyProvId={setShowModalForBodyProvId}
        buttonUI={buttonUI}
      />
    )
  }, [post, hChannelAccounts, showModalForBodyProvId])

  const [isErrored, setIsErrored] = useState(false)

  const method = post.id ? "PUT" : "POST"
  const action = post.id ? `/posts/${post.id}` : "/posts"

  return (
    <div id="PostStateManager" className="w-full space-y-12">
      <ul className="flex border-b gap-5 lg:hidden">
        {mobileTabs.map(tab => (
          <li
            key={tab.value}
            className={`text-base p-2 border-b-2 ${activeMobileTab === tab.value ? "border-brand_main text-brand_main" : "text-gray-500 border-gray-400 cursor-pointer"}`}
            onClick={() => setActiveMobileTab(tab.value)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      <div className={`${activeMobileTab !== "content" && "hidden"} lg:block space-y-12 relative`}>
        <div className="">
          <div className="hidden z-[5] lg:flex -mb-1 gap-1 relative border-limited w-full overflow-x-auto">
            {sortedBodyKeys?.map(bPid => {
              const active = isActive(bPid)
              return <div
                key={`btn-${bPid}`}
                className={`z-0 p-2 px-3 rounded-t-md border-t border-l border-r ${active ? "bg-white " : "bg-gray-100 cursor-pointer"}`}
                onClick={() => setDesktopActiveBody(bPid)}>
                <ChannelAccountsUiElement
                  bPid={bPid}
                  post={post}
                  hChannelAccounts={hChannelAccounts}
                />
              </div>
            })}
          </div>
          <div className="flex flex-col gap-12">
            {sortedBodyKeys?.map((bPid, index) => {
              return <BodyEditor
                key={bPid}
                index={index}
                body={hBodies[bPid]}
                isLast={sortedBodyKeys.length <= 1}
                isActive={isActive(bPid)} // setDesktopActiveBody
                setHBodies={setHBodies}
                onRemoval={onBodyRemoval}
                onDuplication={onBodyDuplication}
                hContentMedia={hContentMedia}
                setHContentMedia={setHContentMedia}
                targetsSelectorRenderer={memoizedSelectorRenderer}
              />
            })}
          </div>
        </div>
        <div className="flex justify-end sticky bottom-0 lg:hidden">
          <div className="backdrop-blur-sm pb-5 pt-3 pl-3">
            <button
              type="button"
              className="m-2 flex rounded-lg py-3 pl-5 pr-3 bg-brand_main hover:bg-brand_darker font-medium text-white gap-1"
              onClick={() => {
                document?.querySelector("#main-container")?.scrollTo({ top: 0, left: 0 })
                setActiveMobileTab("publication")
              }}>
              Suivant
              <ChevronRightIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
      <div className={`${activeMobileTab !== "publication" && "hidden"} lg:block space-y-12`}>
        <PublicationManager
          post={post}
          setPost={setPost}
          hChannelAccounts={hChannelAccounts}
          hBodies={hBodies}
          hContentMedia={hContentMedia}
          variables={props.variables}
          setIsErrored={setIsErrored}
        />
        <div className="flex justify-end sticky bottom-0">
          <div className="backdrop-blur-sm pb-5 pt-3 pl-3">
            {isErrored ? (
              <Tooltip text="Certaines erreurs doivent être corrigées" position="left">
                <button
                  type="submit"
                  className="m-2 flex rounded-lg p-3 pr-5 font-medium gap-3 cursor-not-allowed bg-gray-200 border border-gray-400 text-gray-400"
                  disabled
                >
                  <PaperAirplaneIcon className="w-6 h-6" />
                  Enregistrer
                </button>
              </Tooltip>
            ) : (
              <button
                type="submit"
                className="m-2 flex rounded-lg p-3 pr-5 font-medium gap-3 cursor-pointer bg-brand_main hover:bg-brand_darker text-white"
              >
                <PaperAirplaneIcon className="w-6 h-6" />
                Enregistrer
              </button>
            )}

          </div>
        </div>
      </div>
      {/* Hidden inputs */}
      <HiddenInput name="post[periodicity]" value={post.periodicity} />
      <HiddenInput name="post[scheduled_at]" value={post.scheduled_at} />
      <HiddenInput name="post[instant_publish]" value={post.instant_publish} />
      <PostOnChannelInputs
        originalPocsByCa={originalPocsByCa}
        postOnChannels={post.post_on_channels_attributes}
      />
      <ContentMediaInputs hContentMedia={hContentMedia} />
    </div >
  );
}

// Ajoute les inputs pour créer, modifier et détruire les pocs
const PostOnChannelInputs = memo(({ originalPocsByCa, postOnChannels }: {
  originalPocsByCa: Record<number, number>,
  postOnChannels: ProvisionalPost["post_on_channels_attributes"]
}) => {
  const prefix = "post[post_on_channels_attributes]"
  const channelAccountIds = postOnChannels.map(poc => poc.channel_account_id)
  const destIndex = postOnChannels.length + 1
  const destroyables = Object.entries(originalPocsByCa)
    .filter(([ca, _]) => !channelAccountIds.includes(parseInt(ca)))

  return <> {postOnChannels.map((
    {
      channel_account_id: caId,
      body_provisional_id: bPid,
      forced_scheduled_at,
      channel_specific_data
    }, i
  ) => <div key={caId}>
      <HiddenInput name={`${prefix}[${i}][id]`} value={originalPocsByCa[caId]} />
      <HiddenInput name={`${prefix}[${i}][channel_account_id]`} value={caId} />
      <HiddenInput name={`${prefix}[${i}][body_provisional_id]`} value={bPid} />
      <HiddenInput
        name={`${prefix}[${i}][forced_scheduled_at]`}
        value={forced_scheduled_at}
      />
      {channel_specific_data && Object.entries(channel_specific_data).map(([k, v]) => (
        <HiddenInput
          name={`${prefix}[${i}][channel_specific_data][${k}]`}
          value={v}
        />
      ))}
    </div>
  )}
    {destroyables.map(([c, pid], i) => <div key={c}>
      <HiddenInput name={`${prefix}[${i + destIndex}][id]`} value={pid} />
      <HiddenInput name={`${prefix}[${i + destIndex}][_destroy]`} value={1} />
    </div>)}
  </>
})

const ContentMediaInputs = memo(({ hContentMedia }: {
  hContentMedia: Record<number, ProvisionalContentMedium>
}) => {
  const prefix = "content_media[]"
  return <>
    {Object.values(hContentMedia).map(cm => <div key={`${cm.provisional_id}_body_input`}>
      <HiddenInput name={`${prefix}[provisional_id]`} value={cm.provisional_id} />
      <HiddenInput name={`${prefix}[id]`} value={cm.id} />
      {cm.serialized_file.signature && (
        <HiddenInput
          name={`${prefix}[medium]`}
          value={`${cm.serialized_file.identifier}#${cm.serialized_file.signature}`}
        />
      )}
    </div>
    )}
  </>
})

export default PostStateManager;
