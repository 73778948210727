import React from "react";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

const CustomPopover = (
  { children, classes, button, position = "bottom", customCss }:
  { children: React.ReactNode, classes?: string, button?: React.ReactNode, position?: "top" | "bottom" | "left" | "right", customCss?: string }
) => {
  const positions = {
    top: "bottom-full left-1/2 transform -translate-x-1/2 mb-2 origin-bottom",
    bottom: "top-full right-0 origin-top",
    left: "right-full top-1/2 transform -translate-y-1/2 mr-2 origin-right",
    right: "left-full top-1/2 transform -translate-y-1/2 ml-2 origin-left",
  };

  return (
    <Popover className={`relative z-50 ${classes}`}>
      <PopoverButton>{button}</PopoverButton>

      <PopoverPanel className={`absolute z-10 ${positions[position]} ${customCss}`}>
        {children}
      </PopoverPanel>
    </Popover>
  );
}

export default CustomPopover;
