import { CloudinaryFile } from "./cloudinary_custom_types";
declare global {
	interface Window {
		Turbo?: any;
	}
}
export type Company = {
	id?: number;
	first_name: string;
	last_name: string;
	company: string;
	website: string;
	email: string;
	phone: string;
	hashtag: string;
	displayed_name: string;
	gender: string;
	sendinblue_sender_name: string;
	sendinblue_sender_email: string;
	primary_color: string | null;
	profile_picture?: {
		id?: number,
		serialized_file: CloudinaryFile;
		crop_width: number;
		crop_height: number;
		crop_x: number;
		crop_y: number;
	} | null;
};

export type PostGenerator = {
	starting_at: string | null;
	channels: Channel[];
	periodicity: string;
	id?: number;
	channel_ids: number[];
	content: Content;
	publish_now: string;
};

export type Post = {
	// starting_at: string | null;
	//periodicity: string;
	id?: number;
	//channel_ids: number[];
	company_content: CompanyContent;
	//publish_now: string;
	periodicity: string;
	channel_accounts?: ChannelAccount[];
	channel_account_ids?: number[];
	scheduled_at: string | null;
	post_on_channels?: PostOnChannel[];
};

export type ProvisionalPost = {
	id?: number | null;
	[key: string]: any;
	company_content: {
		body_provisional_id: number;
	};
	periodicity: "one_time" | "daily" | "weekly" | "monthly";
	scheduled_at: string | null; // à modifier par une date
	instant_publish: boolean;
	post_on_channels_attributes:
	{
		id?: number;
		body_provisional_id: number;
		forced_scheduled_at: string; // à modifier par une date
		channel_account_id: number;
		_destroy?: 0 | 1;
    channel_specific_data: Record<string, any> | null
	}[]
}
export type ChannelIdentifier = "facebook" | "linkedin" | "twitter" | "pinterest" | "google_my_business" | "tiktok"
export type ProvisionalCompanyContent = {
	id?: number;
	body_channel_groups_attributes: {
		id?: number,
		body_provisional_id: number,
		channels: ChannelIdentifier[]
	}[]
}
export type ProvisionalContent = ProvisionalCompanyContent
export type CompanyContent = {
	id?: number;
	body: Body;
};

export type ChannelAccount = {
	id?: number;
	channel_id?: number;
	channel?: Channel;
	picture_url?: string;
	displayed_name?: string;
	channel_constraints?: {
		character_limit: number
		display_inline_links: boolean
		embedded_link_count: number
		enforce_video: boolean
		picture_limit: number
		video_count: number
	},
	channel_specific_data: Record<string, any> | null
}

export type Content = {
	focus: string;
	teaser: string;
	description: string;
	reconstructed_url: string;
	title: string;
	id?: number;
	cta: string | null;
	tag_ids: number[];
	// content_media: ContentMedium[];
	body: Body;
	expires_at: string | null;
};

export type Body = {
	id?: number;
	message: string;
	link_url: string | null;
	link_description: string | null;
	link_cta: string | null;
	body_media?: MediaHolder[];
}
export interface ProvisionalBody extends Body {
	provisional_id: number;
	body_media_attributes: {
		position: number;
		content_medium_provisional_id: number;
		id?: number;
	}[];
}
export function isPersisted<Model>(model: Model): model is Model & { id: number } {
	return "id" in model;
}

export function isChannelAccount(ca: ChannelAccount): ca is ChannelAccount {
	return "picture_url" in ca;
}
export type MediaHolder = {
	id?: number,
	destroy?: boolean,
	content_medium: ContentMedium,
	position?: number
}

export type ContentMedium = {
	id?: number;
	//position?: number;
	serialized_file: CloudinaryFile;
	destroy?: boolean
};

export type ProvisionalContentMedium = ContentMedium & { provisional_id: number };

export type Channel = {
	id: number;
	identifier: string;
	name?: string;
};

export type Tag = {
	id: string;
	label: string;
};

export interface TagSubGroup extends Tag {
	tags: Tag[];
}

export interface TagGroup extends Tag {
	tag_sub_groups: TagSubGroup[];
}

export type PostOnChannel = {
	id: number;
	channel_account_id: number;
}

export type inputValues = string | number | boolean | Object | Date | any[] | undefined | null;
