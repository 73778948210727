import { ProvisionalCompanyContent, ProvisionalPost, ChannelAccount, ProvisionalBody, ChannelIdentifier, ContentMedium } from "../types";

type Poc = ProvisionalPost["post_on_channels_attributes"][0]

type Error = {
  message: string;
  action?: () => void;
  globalMessage?: boolean;
}

const validatePost = ({
  pocs,
  instantPublish,
  hChannelAccounts,
  hBodies,
  hContentMedia,
  setOpenModalForPoc
}: {
  pocs: Poc[],
  instantPublish: boolean,
  hChannelAccounts: Record<number, ChannelAccount>,
  hBodies: Record<number, ProvisionalBody>,
  hContentMedia: Record<number, ContentMedium>,
  setOpenModalForPoc: (poc: Poc) => void,
}) => {


  const errors = pocs.map((poc) => {
    const channelAccount = hChannelAccounts[poc.channel_account_id];
    const body = hBodies[poc.body_provisional_id] as ProvisionalBody;
    const constraints = channelAccount?.channel_constraints;
    if (!constraints || !body) {
      return [];
    }
    const foundErrors: Error[] = [];

    applyCoreValidations(
      foundErrors,
      constraints,
      body,
      body.body_media_attributes.map(
        (bma) => hContentMedia[bma?.content_medium_provisional_id]
      )
    )

    if (
      channelAccount?.channel?.identifier === "tiktok" &&
      !poc?.channel_specific_data?.privacy_level
    ) {
      foundErrors.push({
        message: `nécessite une définition manuelle des paramètres du post`,
        action: () => {
          setOpenModalForPoc(poc);
        },
      });
    }

    if (!poc.forced_scheduled_at && !instantPublish) {
      foundErrors.push({
        message: "Certaines publications n'ont pas de date",
        action: () => {
          setOpenModalForPoc(poc);
        },
        globalMessage: true,
      });
    }
    return foundErrors.length > 0
      ? { channelAccount, messages: foundErrors }
      : null;
  })
    .filter((e) => e) as {
      channelAccount: ChannelAccount;
      messages: Error[]
    }[];

  return errors
}

const validateContent = ({
  channels,
  hBodies,
  hContentMedia,
  content,
  channelConstraints
}: {
  content: ProvisionalCompanyContent,
  channels: ChannelIdentifier[],
  hBodies: Record<number, ProvisionalBody>,
  hContentMedia: Record<number, ContentMedium>,
  channelConstraints: Record<ChannelIdentifier, ChannelAccount["channel_constraints"]>
}) => {


  const errors = channels.map((channel) => {

    const bcg = content.body_channel_groups_attributes.find(bcg => bcg.channels.includes(channel))
    if (!bcg) return []
    const body = hBodies[bcg.body_provisional_id] as ProvisionalBody
    const constraints = channelConstraints[channel]
    if (!constraints || !body) { return [] }
    const foundErrors: Error[] = [];

    applyCoreValidations(
      foundErrors,
      constraints,
      body,
      body.body_media_attributes.map(
        (bma) => hContentMedia[bma?.content_medium_provisional_id]
      )
    )
    return foundErrors.length > 0
      ? { channel, messages: foundErrors }
      : null;
  })
    .filter((e) => e) as {
      channel: ChannelIdentifier,
      messages: Error[]
    }[];

  return errors
}


const applyCoreValidations = (
  foundErrors: Error[],
  constraints: NonNullable<ChannelAccount["channel_constraints"]>,
  body: ProvisionalBody,
  media: ContentMedium[]
) => {
  Object.entries(constraints).forEach(([constraint, shouldBe]) => {
    switch (constraint) {
      case "character_limit":
        if (
          body.message &&
          (shouldBe as number) - body.message.length < 0
        ) {
          foundErrors.push({
            message: `limite le message à ${shouldBe} caractères`,
          });
        }
        break;
      //case "display_inline_links":
      //case "embedded_link_count":
      case "enforce_video":
        if (shouldBe) {

          const hasVideo =
            media.filter(
              m => m?.serialized_file.resource_type === "video"
            ).length > 0;

          if (!hasVideo) {
            foundErrors.push({
              message: `requiert une vidéo`,
            });
          }
        }
        break;
      case "picture_limit":
        if ((shouldBe as number) - body.body_media_attributes.length < 0) {
          foundErrors.push({
            message: `limite à ${shouldBe} médias`,
          });
        }
        break;
      // case "video_count":
    }
  });
}

export { validatePost, validateContent };
