import React, { useRef } from "react";
import { ProvisionalBody } from "../types";
import { LinkIcon } from "@heroicons/react/20/solid";
import Tooltip from "./tooltip";
import CustomPopover from "./Popover";

type Props = {
  body: ProvisionalBody;
  updateBody: (data: Object) => void;
  disabled?: boolean;
}
export const ctaOptions = [
  { label: "Choisissez un type d'action", value: undefined },
  { label: "Commander", value: "order_now" },
  { label: "Acheter", value: "shop_now" },
  { label: "En savoir plus", value: "learn_more" },
  { label: "S'inscrire", value: "sign_up" },
  { label: "Appeler", value: "call_now" },
  { label: "Nous contacter", value: "contact_us" },
];

const BodyLink = ({ body, updateBody, disabled }: Props) => {
  const ref = useRef(null);

  return (
    <CustomPopover button={
      <Tooltip text="Insérer un lien" position="bottom">
        <button type="button" className={`flex justify-center items-center text-brand_main bg-gray-100 rounded-full size-9 ${disabled ? "cursor-default" : " hover:bg-gray-200"}`}
          disabled={disabled}
        >
          <LinkIcon className={`size-6 ${disabled ? "text-gray-400 hover:text-gray-400" : "hover:text-brand_darker"}`} />
        </button>
      </Tooltip>
    }
      position="top"
      customCss="!translate-x-[-20px] !translate-y-[50px]"
    >
      <div className="z-10 p-4 bg-white my-auto relative rounded-md w-[400px] border" ref={ref}>
        <div className="flex flex-col justify-center h-full gap-4">
          <div className="flex flex-col gap-2 items-start w-full">
            <div className="flex w-full relative">
              <span className="absolute flex h-full w-auto px-3 items-center top-0 left-0 text-gray-500 text-sm bg-gray-200 border border-e-0 border-gray-300 rounded-l-md -mr-4">https://</span>
              <input
                type="text"
                id={`bodies[${body.provisional_id}][link_url]`}
                placeholder="example.com"
                value={body?.link_url?.replaceAll(/https?:\/\//g, "")}
                onChange={(event) => {
                  updateBody({ link_url: "https://" + event?.target?.value.replaceAll(/https?:\/\//g, "") }) // on re-remplace pour éviter les cas edgy
                }}
                className="flex flex-1 w-full border-gray-300 rounded-md shadow-sm focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
                style={{ paddingLeft: "5rem" }}

              />
            </div>
            <input
              type="text"
              id={`bodies[${body.provisional_id}][link_description]`}
              disabled={!body?.link_url}
              placeholder="Description du lien"
              value={body?.link_description ?? ""}
              onChange={(event) => updateBody({ link_description: event.target.value })}
              className="custom-form-input w-full"
            />
            <select
              disabled={!body?.link_url}
              className="custom-form-input w-full"
              id={`bodies[${body.provisional_id}][link_cta]`}
              value={body?.link_cta || "Aucun"}
              onChange={(event) => updateBody({ link_cta: event.target.value })}
            >
              {ctaOptions.map((opt, index) => (
                <option key={`${opt.value}_${index}`} value={opt.value}>{opt.label}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </CustomPopover>
  );
}

export default BodyLink;
