import { Channel, ChannelAccount, ProvisionalBody, ProvisionalPost } from "../types";
import { sortChannelAccounts } from "../utils/various";
import ChannelAccountImage from "./ChannelAccountImage";
import Modal from "./Modal";
import React, { memo, useEffect, useMemo, useState } from "react";
import { UsersIcon } from "@heroicons/react/24/solid";
import Tooltip from "./tooltip";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

const channelAccountsForBPid = (
  bPid: ProvisionalBody["provisional_id"],
  post: ProvisionalPost,
  hChannelAccounts: Record<number, ChannelAccount & { id: number }>
) => post.post_on_channels_attributes
  .filter(({ body_provisional_id }) => body_provisional_id === bPid)
  .map(({ channel_account_id: Cid }) => hChannelAccounts[Cid])

export const ChannelAccountsUiElement = memo(({ bPid, post, hChannelAccounts }: {
  bPid: ProvisionalBody["provisional_id"]
  post: ProvisionalPost,
  hChannelAccounts: Record<number, ChannelAccount & { id: number }>
}) => {
  const selectedAccounts = channelAccountsForBPid(bPid, post, hChannelAccounts)
  const shownAccounts = selectedAccounts.slice(0, 3)
  const moreAccounts = selectedAccounts.length - shownAccounts.length
  return (
    <div className="flex gap-1 font-medium text-gray-500">
      <div className="flex flex-row-reverse mb-1 ml-2">
        {shownAccounts?.reverse()?.map((ca) => (
          <div key={ca?.id} className="relative -ml-2">
            <ChannelAccountImage
              identifier={ca?.channel?.identifier as Channel['identifier']}
              ca={ca}
              withOutline={true}
              isActive={true}
              imageSize={10}
              logoSize={4}
              withChannelLogo={true}
            />
          </div>
        ))}
      </div>
      {Boolean(moreAccounts) && <span className="content-center text-sm ">+{moreAccounts}</span>}
    </div>
  )
})

export const ChannelAccountsSelector = memo((
  {
    bPid,
    post,
    hChannelAccounts,
    switchBodyPocs,
    showModalForBodyProvId,
    setShowModalForBodyProvId,
    destroyEmptyBody,
    buttonUI
  }:
    {
      bPid: ProvisionalBody["provisional_id"]
      post: ProvisionalPost,
      hChannelAccounts: Record<number, ChannelAccount & { id: number }>,
      switchBodyPocs: (targetsIds: number[], bodyId: ProvisionalBody["provisional_id"]) => void,
      showModalForBodyProvId: number | null,
      setShowModalForBodyProvId: React.Dispatch<React.SetStateAction<number | null>>
      destroyEmptyBody: (bPid: ProvisionalBody["provisional_id"]) => void
      buttonUI?: JSX.Element
    }) => {
  const selectedAccounts = channelAccountsForBPid(bPid, post, hChannelAccounts)
  const targets = Object.values(hChannelAccounts) as ChannelAccount[]
  const sortedChannelAccounts: { [identifier: Channel["identifier"]]: ChannelAccount[] } = sortChannelAccounts(targets)
  const targetIds = selectedAccounts.map(ca => ca?.id)
  // État interne des targets provenant du body dupliqué et des Channels Accounts cochés
  const [selectedTargets, setSelectedTargets] = useState(targetIds);
  const hasPoc = useMemo(() => post.post_on_channels_attributes.some(poc => poc.body_provisional_id === bPid), [post])

  // Message d'erreur si aucune target cochée
  const [error, setError] = useState({ status: false, message: "" });

  // Pour une raison mystérieuse,
  // la synchronisation de l'état ne fonctionne pas
  // On est obligés de forcer la mise à jour
  useEffect(() => {
    setSelectedTargets(channelAccountsForBPid(bPid, post, hChannelAccounts).map(ca => ca?.id as number));
  }, [post])

  const resetError = () => setError({ status: false, message: "" })

  const onAction = (type: "cancel" | "validate") => {
    setError({ status: false, message: "" })
    if (type === "validate") {
      if (!selectedTargets.length) return setError({ status: true, message: "Veuillez choisir au moins un compte" })
      switchBodyPocs(selectedTargets, bPid)
    }
    // Si on annule, on réinstalle l'état initial avec les données du back-end
    if (type === "cancel") {
      // Si le body n'a pas de POC, on le supprime
      if (!hasPoc) {
        destroyEmptyBody(bPid)
      }
      // On attend un peu pour pas que les checkbox se recochent tout de suite
      setTimeout(() => setSelectedTargets(targetIds), 500)
    }
    setShowModalForBodyProvId(null)
  }

  return (
    <div className="flex">
      <Modal open={bPid === showModalForBodyProvId} setOpen={() => null}>
        <div className="py-24">
          <div className="flex flex-col gap-4 p-6 my-auto bg-white rounded-lg shadow max-h-xl">
            <div className="flex flex-col items-start w-full space-y-4">
              <div className="text-start">
                <h3 className="font-semibold text-md md:text-lg">Réseaux sociaux</h3>
                <p className="text-sm text-gray-500 sm:text-base">
                  {selectedTargets.length} comptes sélectionnés
                </p>
              </div>
              {error.status &&
                <div>
                  <p className="text-sm text-red-500">
                    <ExclamationCircleIcon className="inline w-6 h-6" />
                    {error.message}
                  </p>
                </div>
              }
            </div>
            <div className="flex flex-col gap-4 p-4 overflow-y-auto border-gray-300 border-y">
              {Object.entries(sortedChannelAccounts)
                .map(([identifier, accounts], i) => {
                  return <div key={identifier} className="flex flex-col items-start gap-3 ">
                    <label
                      className="flex items-center cursor-pointer"
                    >
                      {accounts.length > 1 &&
                        <input
                          type="checkbox"
                          className="w-4 h-4 mr-4 border-gray-300 rounded form-checkbox opacity-90 text-brand_main focus:border-brand_focus focus:ring focus:ring-brand_focus focus:bg-white"
                          checked={
                            accounts.every((ca) => selectedTargets?.includes(ca.id as number))
                          }
                          onChange={(e: any) => {
                            resetError()
                            if (e?.target?.checked) {
                              setSelectedTargets([...selectedTargets, ...accounts.map((ca) => ca.id as number)]);
                            } else {
                              setSelectedTargets(selectedTargets.filter((id) => !accounts.map((ca) => ca.id as number).includes(id)));
                            }
                          }}
                        />
                      }

                      <span className="font-semibold">
                        {identifier[0].toUpperCase() + identifier.slice(1)}
                      </span>
                    </label>
                    <div className="flex flex-col items-start gap-4 pl-4">
                      {accounts.map((ca) => (
                        <label
                          key={ca.id}
                          className="flex items-center gap-4 !cursor-pointer w-full"
                        >
                          <input
                            id={`${ca?.id}`}
                            className="w-4 h-4 border-gray-300 rounded form-checkbox text-brand_main opacity-90 focus:border-brand_focus focus:ring focus:ring-brand_focus focus:bg-white"
                            value={ca?.id}
                            type="checkbox"
                            checked={selectedTargets.includes(ca.id as number)}
                            onChange={(e) => {
                              resetError()
                              if (e.target.checked) {
                                setSelectedTargets(prev => [...prev, (ca?.id as number)])
                              } else {
                                setSelectedTargets(prev => prev.filter(caId => ca.id !== caId))
                              }
                            }}
                          />
                          <div className="flex items-center w-full gap-4 group">
                            <div className="relative w-12 min-w-12 max-w-12">
                              <ChannelAccountImage
                                identifier={ca?.channel?.identifier as Channel['identifier']}
                                ca={ca}
                                withOutline={true}
                                isActive={selectedTargets?.includes((ca.id as number))}
                                imageSize={10}
                                logoSize={4}
                                withChannelLogo={true}
                              />
                            </div>

                            <div className="flex flex-col items-start w-36 overflow-hidden text-sm text-gray-500 ">
                              <span className="text-left line-clamp-2" lang="fr">
                                {ca.displayed_name}
                              </span>
                            </div>
                          </div>
                        </label>
                      ))}
                    </div>
                  </div>
                })}
            </div>
            <div className="flex justify-between gap-1 sm:gap-5">
              <button
                type="button"
                className="btn-neutral"
                onClick={() => onAction("cancel")}
              >
                Annuler
              </button>
              <button
                type="button"
                className="px-4 py-2 text-base btn-brand-primary"
                onClick={() => onAction("validate")}
              >
                Valider
              </button>
            </div>
          </div>

        </div>
      </Modal>
      <button
        type="button"
        className=""
        onClick={() => setShowModalForBodyProvId(bPid)}>
        {buttonUI ? buttonUI : 
          <>
            <div className="lg:hidden">
              <ChannelAccountsUiElement
                bPid={bPid}
                post={post}
                hChannelAccounts={hChannelAccounts}
                />
            </div>
            <Tooltip text="Sélectionner vos réseaux sociaux" position="bottomright">
              <div className="hidden btn-neutral lg:flex">
                <UsersIcon className="p-0 size-5 mr-2" /> Réseaux
              </div>
            </Tooltip>
          </>
        }
      </button>
    </div>

  )
})
