import React, { useEffect, useState } from "react";
import { useFloating, autoPlacement, offset, computePosition } from '@floating-ui/react-dom';

const Tooltip = ({ children, text }: { children: React.ReactNode, text: string, position?: string }) => {


  const { refs, floatingStyles } = useFloating({ middleware: [offset(5), autoPlacement()] });
  const [styles, setStyles] = useState(floatingStyles);

  var options = {
    root: document.documentElement,
    rootMargin: "0px",
    threshold: 1.0,
  };


  var observer = new IntersectionObserver((entries) => {
    computePosition(
      refs.reference.current,
      refs.floating.current,
      { middleware: [offset(5), autoPlacement()] }
    )
      .then(({ x, y }) => {
        setStyles(prev => ({
          ...prev,
          left: `${x}px`,
          top: `${y}px`,
          margin: "6px"
        }));
      })
  }, options);

  useEffect(() => {
    if (refs.reference.current) {
      observer.observe(refs.reference.current);
    }
    return () => {
      if (refs.reference.current) {
        observer.unobserve(refs.reference.current);
      }
    }
  }, [refs.reference])


  return (
    <div ref={refs.setReference} className="relative flex items-center group">
      {children}
      <div ref={refs.setFloating} style={styles} className={`w-48 z-40`}>
        <div className={`min-w-32 delay-700 max-w-48 w-fit hidden group-hover:block bg-gray-700 text-white text-sm rounded py-2 px-3 bg-opacity-90 `}>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
